import React from "react";

import LogoSrc from "../../images/logo.png";

const Logo: React.FC<{ big?: boolean }> = ({ big }) => {
  return (
    <img
      src={LogoSrc}
      alt="Logo"
      style={{ height: big ? "auto" : 35, width: big ? "35vw" : undefined }}
    />
  );
};

export default Logo;
