import { graphql, useStaticQuery } from "gatsby";
import { PageType } from "./types";

export const useRoutes: () => PageType[] = () => {
  const query = useStaticQuery(graphql`
    query AllRoutesQuery {
      allContentfulContentPage {
        edges {
          node {
            id
            title
            order
            thumbnail {
              fixed(width: 100, quality: 30) {
                src
              }
            }
          }
        }
      }
      allContentfulGalleryPage {
        edges {
          node {
            id
            title
            order
            thumbnail {
              fixed(width: 100, quality: 30) {
                src
              }
            }
          }
        }
      }
    }
  `);

  if (!query) return [];

  const contentPages: PageType[] = query.allContentfulContentPage.edges.map(
    (page) => page.node
  );
  const galleryPages: PageType[] = query.allContentfulGalleryPage.edges.map(
    (page) => page.node
  );

  const pages = [...contentPages, ...galleryPages];
  const orderedPages = pages.sort((a, b) => a.order - b.order);
  return orderedPages;
};
